import React from "react"

const brandStyle = {
  fill: `#fffacc`,
}

export default function() {
  return (
    <svg
      style={brandStyle}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="449.41px"
      height="561.48px"
      viewBox="0 0 449.41 561.48"
      xmlSpace="preserve"
    >
      <path
        d="M36.49,507.83h6.69l-3.43,31.64h13.22l8.43-77.96H48.18l-3.43,31.64h-6.69l3.43-31.64H28.26l-8.43,77.96
			h13.22L36.49,507.83z M208.17,539.47l1.57-14.68h-12.89l5.29-48.61h12.89l1.57-14.68h-26.12l-8.43,77.96H208.17z M335.2,539.47
			h13.31l1.57-14.68h-13.31L335.2,539.47z M228.09,539.47l3.43-31.64h6.69l-3.43,31.64h13.22l8.43-77.96h-13.22l-3.43,31.64h-6.69
			l3.43-31.64h-13.22l-8.43,77.96H228.09z M382.88,524.8h-12.89l5.29-48.61h12.89l1.57-14.68h-26.12l-8.43,77.96h26.12L382.88,524.8
			z M154.62,539.47l3.55-33.02h2.48l3.97,33.02h13.22l-4.3-33.02h5.41l4.83-44.94h-13.22l-3.26,30.27h-7.52l3.26-30.27h-13.22
			l-8.43,77.96H154.62z M401.23,539.47l3.43-31.64h6.69l-3.43,31.64h13.22l8.43-77.96h-13.22l-3.43,31.64h-6.69l3.43-31.64h-13.22
			l-8.43,77.96H401.23z M136.27,524.8h-12.89l5.29-48.61h12.89l1.57-14.68h-26.12l-8.43,77.96h26.12L136.27,524.8z M449.41,0H0
			v561.48h449.41l0-110.98h0V0z M87.36,550.48h-15.7l1.2-11.01h15.7L87.36,550.48z M85.66,524.8h-6.82l7.6-48.61h4.5L85.66,524.8z
			 M439.5,550.48H291.15l1.19-11.01h12.95l10.25-27.79h2.77l-3.02,27.79h13.22l8.43-77.96h-33.97l-5.45,50.17h5.08l-10.15,26.77
			l1.47-13.65h-4.5l6.86-63.29h-13.22l-6.86,63.29h-6.69l6.86-63.29h-13.22l-8.43,77.96h24.42l-1.2,11.01H100.58l2.81-25.68h-4.5
			l6.86-63.29H75.5l-9.88,63.29H61.2l-2.77,25.68H9.92V450.5H439.5V550.48z M312.35,497l2.23-20.82h7.56L319.87,497H312.35z
			 M439.5,439.5H9.92V9.92H439.5V439.5z M114.61,301.01c2.5,6.09,3.72,14.1,3.59,24.03c-1.92-0.26-3.97-0.26-6.22,0
			c-1.86,0.26-3.84,0.58-6.02,1.09c-7.24,1.67-11.79,2.43-13.71,2.43c3.78,3.52,8.33,6.34,13.71,8.46c1.79,0.77,3.78,1.47,6.02,2.05
			c4.87,1.41,9.87,2.11,14.99,2.11c3.65,0,7.24-0.51,10.7-1.54c-0.45-1.09-2.18-5.77-5.06-13.9c-1.99-5.51-3.84-9.8-5.64-12.94
			C123.64,307.16,119.48,303.25,114.61,301.01z M143.31,346.05c-8.26,2.62-13.71,4.1-16.34,4.48c-4.23,0.64-7.81,0.45-10.7-0.58
			c2.56,2.24,5.57,4.55,9.23,6.79c0.26,0.26,0.77,0.64,1.47,1.09c5.77,3.65,13.78,3.91,24.03,0.77c1.03-0.26,2.95-0.83,5.83-1.66
			c2.5-0.9,4.49-1.54,5.83-1.92c-0.9-1.73-2.75-5.77-5.64-12.17c-2.24-5.13-4.23-9.1-6.02-11.85c-3.59-5.57-8.07-9.35-13.33-11.21
			h-0.32c1.86,3.08,3.08,7.43,3.72,12.94C141.96,339.71,142.73,344.13,143.31,346.05z M84.36,296.08c1.28,0.51,2.24,0.96,2.82,1.35
			c0-0.38,0.13-0.77,0.38-1.15c2.37-10,3.65-16.98,3.78-21.01c0.51-7.88-1.28-14.35-5.25-19.48h-0.39l-4.29,14.03l-3.01,9.8
			c-4.29-2.18-10.38-3.78-18.39-4.93c-1.02-0.19-3.01-0.45-6.02-0.71c0.13,0.39,0.7,1.09,1.66,2.24c1.28,2.37,2.69,4.49,4.36,6.41
			c3.84,4.1,10.96,8.2,21.4,12.36C81.99,295.25,83.02,295.63,84.36,296.08z M97.69,109.9c3.52-0.51,6.28-1.03,8.27-1.67
			c2.24-0.64,4.29-1.54,6.02-2.63c0.51-0.26,0.96-0.58,1.35-0.96c5.13-4.87,7.69-9.61,7.69-14.22l-9.03,2.63l-6.02,1.86l-6.53,2.05
			c1.34-8.65,0.38-18.64-3.01-30.05v-0.51c-5.77,15.5-8.52,22.81-8.26,21.91c-2.24,8.26-1.79,15.89,1.34,22.94
			C90.2,111.11,92.95,110.66,97.69,109.9z M293.87,335.93c-1.41,2.5-3.08,5.96-5.06,10.51c-2.24,5-3.85,8.46-4.68,10.51
			c2.76,0.13,5.96,0.64,9.74,1.66c11.53,2.88,19.03,4.1,22.55,3.59c0,0,1.09-0.26,3.33-0.77c5.25-1.6,8.97-4.23,11.08-7.88
			c0.13,0-2.94-0.26-9.16-0.77c-0.13,0-0.77-0.19-1.92-0.58c-6.86-2.24-12.75-3.59-17.62-4.1c0-0.26,0.06-0.58,0.19-0.96
			c1.99-2.5,3.4-7.18,4.1-14.03c0.64-6.79,2.11-11.4,4.55-13.9l-0.19-0.19C303.99,322.03,298.35,327.67,293.87,335.93z
			 M165.82,295.44c17.79,12.15,37.83,16.69,58.01,17.11c11.01-0.15,20.87-1.25,30.5-3.88c14.86-4.05,28.58-10.33,39.38-21.7
			c8.32-8.76,13.27-19.06,12.71-31.41c-0.28-6.1-3.12-10.78-9.2-12.82c-1.67-0.56-1.99-1.47-2.08-3.05
			c-0.29-4.97-1.66-9.63-4.96-13.48c-1.17-1.36-1.2-2.48-0.62-4.04c2.19-5.88,4.19-11.83,6.39-17.71
			c4.3-11.49,5.38-23.32,3.81-35.41c-0.82-6.31-2.37-12.56-2.76-18.89c-0.99-15.83-3.03-31.48-5.66-47.12
			c-2.96-17.61-12.58-29.59-28.59-36.53c-6.3-2.73-12.99-4.93-19.72-6.25c-20.66-4.08-40.71-1.88-59.58,7.84
			c-11.15,5.75-20.13,14.04-23,26.62c-2.93,12.84-4.68,25.96-6.55,39.01c-1.45,10.09-1.95,20.32-3.47,30.4
			c-2.16,14.28-1.55,28.18,3.72,41.75c2.07,5.32,3.83,10.76,5.84,16.11c0.62,1.65,0.39,2.77-0.58,4.32
			c-1.76,2.82-3.58,5.87-4.2,9.06c-0.78,4.02-1.67,6.79-5.85,9.07c-6.92,3.78-6.98,11.08-5.89,17.97
			C145.74,276.86,154.06,287.4,165.82,295.44z M235.71,150.7c9.94-6.49,20.33-12.01,32.18-14.12c9.91-1.77,15.93,4.87,13.19,14.55
			c-1.91,6.73-6.59,10.47-13.35,11.71c-1.93,0.35-3.9,0.48-5.34,0.66c-10.16-0.46-18.75-3.91-26.74-9.09
			C233.78,153.18,233.91,151.87,235.71,150.7z M242.99,209.33c4.36,2.25,4.62,5.18,0.78,8.17c-5.59,4.35-11.93,6.51-18.77,6.39
			c-7.25,0.08-13.51-2.06-19.06-6.27c-3.99-3.02-3.7-6.11,0.72-8.35C218.78,203.13,230.91,203.11,242.99,209.33z M181.32,136.42
			c2.83,0.82,8.14,1.8,12.96,3.91c6.57,2.89,12.81,6.56,19.1,10.07c2.66,1.49,2.62,2.67,0.12,4.27c-7.36,4.71-15.26,8.2-24.07,8.61
			c-7.94,0.37-15.51-0.92-19.64-9.05c-0.88-1.72-1.51-3.67-1.77-5.58C166.98,141.11,171.25,136.39,181.32,136.42z M111.98,88.69
			c4.23-2.24,7.3-5.12,9.23-8.65c1.6-2.63,3.52-7.18,5.77-13.65c0.9-3.01,1.86-5.7,2.82-8.07c-1.22,0.19-2.18,0.39-2.82,0.51
			c-2.95,0.9-8.01,4.23-14.99,10l-0.38,0.32c-2.24,2.88-4.1,6.28-5.64,10.19c-1.99,4.87-2.95,9.29-2.82,13.33
			c1.54-0.64,2.43-1.03,2.82-1.15C108.45,90.48,110.5,89.59,111.98,88.69z M58.48,222.14c0.51-0.64,1.02-1.28,1.54-2.05
			c10.38-14.87,14.86-28.9,13.45-42.03l-0.13-0.19c-0.26,0.13-0.39,0.45-0.39,0.9c-8.14,10.32-12.49,15.83-12.94,16.72
			c-1.02,1.67-1.86,3.33-2.5,5.06c-1.35-2.63-3.85-5.7-7.5-9.16c-3.72-3.78-6.21-6.66-7.5-8.65c-0.13,0.26-0.26,0.7-0.38,1.28
			c0,0.51-0.13,0.96-0.39,1.34C40.93,198.63,46.5,210.87,58.48,222.14z M55.66,239.96c2.11,2.31,3.59,3.78,4.36,4.55
			c1.09,0.96,2.31,1.86,3.52,2.62c10.12-17.04,14.03-30.3,11.66-39.79h-0.38c-0.51,1.09-5.45,8.2-14.8,21.21
			c-0.13,0.26-0.45,0.7-0.96,1.28l-17.62-18.77c0.19,10.38,3.33,18.58,9.36,24.6C52.14,236.82,53.8,238.23,55.66,239.96z
			 M81.22,126.28c-0.37,0.13,3.65-0.33,11.98-1.33c5.25-0.64,9.55-3.01,12.75-7.17c1.28-1.47,2.18-3.08,2.82-4.68
			c-1.22,0.13-2.18,0.26-2.82,0.38c-10.25,1.22-17.56,1.86-21.91,1.86c0-2.63-0.26-5.58-0.77-8.84c-0.38-1.86-1.02-3.91-1.86-6.15
			c-1.54-4.16-2.37-7.24-2.5-9.42l-0.13-0.39c-0.64,1.15-1.28,2.95-1.92,5.45c-0.64,2.5-1.22,4.42-1.86,5.64
			c-4.23,11.15-4.16,20.05,0.38,26.65C76.95,127.47,78.88,126.79,81.22,126.28z M319.75,342.85c7.88,0.64,13.33,0.77,16.34,0.38
			c3.65-0.51,6.92-1.66,9.74-3.4c3.91-2.88,6.02-4.68,6.41-5.45c0.38-0.64,0.58-1.15,0.58-1.66h-0.58
			c-1.47,0.13-6.86-0.45-16.15-1.73c-4.23-0.58-7.88-0.45-10.89,0.38c-0.13-0.51-0.13-0.96,0-1.47c1.79-2.63,2.88-7.17,3.4-13.52
			c0.39-6.28,1.35-10.51,3.01-12.75c-0.64,0-1.34,0.19-2.05,0.51c-0.9,0.38-1.66,0.64-2.31,0.77c-2.95,1.79-5.51,4.23-7.5,7.3
			c-1.99,3.14-3.97,8.14-6.02,15.05c-2.11,7.5-3.85,12.56-5.25,15.18C311.1,342.21,314.88,342.34,319.75,342.85z M61.88,156.09
			c1.73-2.37,4.68-4.42,8.84-6.22c5.38-2.37,8.65-3.97,9.93-4.87c0.13-0.13,0.38-0.32,0.77-0.58c5.25-3.72,8.33-8.39,9.35-13.9
			c-2.63,0-5.77,0.51-9.35,1.54c-8.37,2.17-12.67,3.37-13,3.56c0.38-3.01-0.2-6.78-1.67-11.25c-1.98-5.77-3.14-9.35-3.4-10.7
			l-0.38-0.58c-0.71,1.99-1.73,4.61-2.95,7.88c-2.18,5.89-3.59,10.76-4.36,14.67c-0.64,7.5,0.83,13.58,4.36,18.39
			C60.6,154.74,61.24,155.45,61.88,156.09z M57.2,189.47c0.58-1.86,1.54-3.78,2.82-5.77c1.47-2.18,3.4-4.49,5.77-6.98
			c8.52-8.65,13.71-16.34,15.63-23.06c0.06-0.77,0.19-1.28,0.32-1.54c-0.13,0.13-0.26,0.19-0.32,0.19
			c-10.06,3.65-17.17,7.82-21.4,12.62c-0.26,0.26-0.7,0.77-1.34,1.66l-9.55-16.72c-0.51,2.37-1.41,7.94-2.63,16.72
			C45.6,175.76,49.19,183.38,57.2,189.47z M404.07,218.43c-3.01,1.22-8.26,5.57-15.76,12.94c-0.26,0.13-0.38,0.26-0.38,0.38
			l-13.9-21.59h-0.58c-3.14,6.47-2.76,13.84,1.15,22.1c5,10.89,7.62,17.81,7.88,20.7c1.86-1.54,3.78-3.2,5.83-5.06
			c6.34-6.15,10.06-9.67,11.02-10.57c5.9-5.96,8.39-13.07,7.56-21.2C407.01,216.12,406.05,216.89,404.07,218.43z M358.07,136.74
			c2.5,4.87,5.19,8.91,8.07,12.05c0.96,0.7,1.73,1.28,2.24,1.67c5.64,5,8.14,7.3,7.49,6.92c1.41,0.77,3.4,1.99,6.02,3.59
			c2.75,1.73,4.68,2.95,5.83,3.59l0.58-0.58c7.11-11.53,7.18-25.89,0.19-43.18l-0.19-0.39c-5.51,12.43-8.39,20.12-8.65,23.13
			l-11.28-3.59L358.07,136.74z M327.63,76.13c0.38,0.64,1.15,2.11,2.24,4.49c1.03,1.99,1.92,3.59,2.63,4.68
			c0.64,0.38,1.86,1.47,3.59,3.2c4.04,3.65,7.24,5.45,9.74,5.45c0-6.85-0.77-12.49-2.44-16.85c-1.99-3.4-4.42-6.41-7.3-9.04
			c-4.49-4.03-9.93-6.92-16.34-8.65c-0.38-0.13-0.58-0.13-0.58,0l-0.51,0.19c0.13,0.13,0.45,0.58,1.09,1.35
			C322,63.7,324.62,68.76,327.63,76.13z M388.31,198.31c-1.15-1.35-3.59-3.78-7.37-7.3c-3.14-2.88-5.13-5.25-5.96-7.11l-0.96-0.19
			c-1.15,5.51-0.13,11.27,3.01,17.43c1.09,2.37,3.01,5.64,5.64,9.74c2.63,4.17,4.49,7.37,5.64,9.61c0.58,1.6,1.09,3.07,1.47,4.29
			c12.04-12.75,18.07-24.86,18.19-36.39l-17.62,13.52C389.84,200.74,389.14,199.59,388.31,198.31z M388.63,170.89
			c-0.19-0.13-0.32-0.26-0.32-0.32c-5.19-3.65-8.33-5.83-9.61-6.6c-6.02-2.88-9.42-4.36-10.32-4.49c-0.13-0.13-0.38-0.19-0.77-0.19
			c0.13,1.34,0.38,2.75,0.77,4.1c1.03,4.42,3.78,9.03,8.26,13.9c0.9,0.9,2.82,2.82,5.83,5.83c2.5,2.5,4.42,4.55,5.83,6.15
			c1.47,1.92,2.69,3.52,3.52,4.93c10.25-14.54,13.33-28.06,9.23-40.55l-0.19-0.19l-0.77,0.19l-10.89,17.81
			C389.07,171.34,388.88,171.15,388.63,170.89z M336.09,97.15l-9.74-1.86c0.64,1.34,0.9,2.05,0.9,2.05
			c2.88,4.23,5.83,7.43,8.84,9.55c3.4,2.76,7.18,4.61,11.47,5.45c0.51,0.13,1.28,0.26,2.44,0.39c1.02,0.26,1.73,0.45,2.24,0.58
			c4.36,0.9,7.56,1.86,9.55,3.01c-0.45-2.63-0.58-3.72-0.32-3.2c2.24-14.99,0.39-28.83-5.45-41.45h-0.38
			c-1.41,1.86-2.5,3.91-3.4,6.15c-2.24,5.77-3.2,13.01-2.82,21.78L336.09,97.15z M352.24,118.74c-2.75-0.13-6.09-0.06-9.93,0.19
			c2.37,3.84,5.7,6.98,9.93,9.35c2.24,1.28,7.62,3.33,16.15,6.21c3.14,1.15,5.57,2.11,7.3,3.01c4.55-14.99,3.71-28.13-2.43-39.21
			c-2.11,1.86-3.72,4.55-4.87,8.07c-1.48,4.48-2.05,9.67-1.66,15.57C362.94,120.15,358.13,119.12,352.24,118.74z M277.15,360.28
			c-0.13-0.13-0.19-0.45-0.19-1.09c0.64-0.51,2.82-3.59,6.6-9.23c0.13-1.6,1.47-7.43,4.1-17.43c-7.37,2.24-16.27,10.83-26.65,25.69
			c-24.28,13.13-48.11,14.1-71.5,2.82c-2.37-1.98-7.05-7.5-14.1-16.53c-5.25-6.85-10.76-11.21-16.53-13.13
			c-0.06,0.13-0.19,0.19-0.32,0.19c0,0.9,0.32,1.6,0.9,2.05c0.77,5.64,4.48,13.97,11.08,24.99c-2.37,0.64-5.64,1.67-9.74,3.2
			c-4.42,1.47-7.62,2.56-9.8,3.21c-4.36,1.09-8.2,1.47-11.6,1.09c0.96,0.25,2.11,0.96,3.4,2.05c2.5,1.79,5.25,3.14,8.2,4.16
			c10.44,3.01,21.66,1.03,33.83-6.02c8.84,0,16.85,2.11,24.03,6.41l-23.26,7.3v1.35c1.73,5,3.46,7.88,5.26,8.58
			c2.62-1.34,7.17-3.46,13.65-6.34c6.15-2.63,10.76-4.74,13.9-6.41c4.61,0.13,10.32,1.99,17.11,5.64
			c9.23,4.87,14.67,7.62,16.34,8.26c1.22-0.64,1.92-1.03,2.05-1.15l3.78-7.11c-2.18-1.99-5.96-3.72-11.47-5.06
			c-6.15-1.54-10.06-2.88-11.85-4.16c-0.13,0.13,8.39-2.31,25.56-7.3c2.75,0,5.83,1.15,9.35,3.4c4.29,2.75,7.37,4.36,9.42,4.87
			c4.87,1.28,9.93,1.28,15.19,0c3.85-0.77,7.11-2.11,9.74-4.1c-2.63-0.64-5.89-2.05-9.74-4.17
			C286.63,362.45,281.06,360.4,277.15,360.28z M105.96,320.68c1.79-0.13,3.78-0.32,6.02-0.58h0.58c-0.13-0.06-0.26-0.32-0.38-0.7
			c0-0.38-0.06-0.64-0.19-0.77c-0.9-2.88-1.86-7.43-3.01-13.71c-1.09-6.15-2.11-10.64-3.01-13.52c-1.86-5.64-4.55-9.8-8.07-12.56
			h-0.7l-2.82,26.65l-12.94-2.24l-8.84-1.73c1.86,1.28,4.81,4.42,8.84,9.42c2.11,2.75,4.16,4.81,6.15,6.21
			C91.35,319.91,97.5,321.06,105.96,320.68z M60.02,266.67c4.23,2.82,9.29,4.68,15.18,5.38c6.34-15.38,7.56-28.45,3.59-39.21
			l-0.38-0.19c-0.39,0.77-0.9,2.05-1.54,3.97c-0.64,1.73-1.15,3.08-1.67,3.91c-5.38,8.78-8.2,13.46-8.46,14.1
			c-1.98-1.35-4.23-2.88-6.73-4.49c-5.89-3.65-10.25-7.24-13.01-10.7c2.43,11.72,5.7,19.99,10,24.73
			C57.97,265.07,58.99,265.9,60.02,266.67z M390.55,279.04h-2.24c-8.39,0.77-15.05,2.5-19.92,5.25c-0.13,0-0.45,0.19-0.96,0.58
			l-8.97-25.37v-0.38c-0.77,0.64-1.41,1.34-1.92,2.11c-3.59,4.36-4.36,11.15-2.24,20.44c2.37,10.25,2.69,17.3,0.96,21.21
			c1.86-1.73,5.51-3.2,10.89-4.29c0.26,0,0.64-0.13,1.09-0.39c0.51-0.13,0.9-0.26,1.15-0.38c9.42-4.04,16.02-8.14,19.92-12.43
			c1.73-1.99,3.14-4.23,4.29-6.73C391.83,278.53,391.13,278.65,390.55,279.04z M368.38,306.46c-7.11,0.83-12.49,1.73-16.15,2.62
			c-1.6,0.32-2.95,0.71-3.91,1.09c0.96-3.27,1.15-7.49,0.51-12.75c-0.96-6.66-1.41-10.83-1.28-12.56l-0.19-0.38
			c-5.25,3.97-9.03,11.66-11.28,23.07c-1.73,9.03-3.52,15.18-5.25,18.58c1.28-0.26,3.01-0.58,5.25-0.9
			c1.99-0.26,3.91-0.45,5.64-0.58c4.48-1.03,8.01-1.92,10.51-2.82c6.98-2.37,12.37-5.45,16.15-9.23c1.99-1.98,3.65-4.23,4.87-6.73
			C373.51,305.75,371.9,305.95,368.38,306.46z M388.31,253.48c-4.29,2.05-7.69,4.48-10.19,7.37c-5.83-14.16-8.78-22.42-8.78-24.8
			l-0.38-0.19l-0.58,0.19v0.39c-0.26,0.51-0.83,1.22-1.66,2.24c-4.42,9.87-3.85,21.78,1.66,35.69c0.77,2.11,1.47,3.84,2.24,5.25
			c1.86-1.92,4.68-3.78,8.46-5.64c5-2.5,8.07-4.1,9.23-4.68c0.13-0.13,0.32-0.32,0.71-0.58c0.26-0.26,0.51-0.45,0.77-0.58
			c5.13-4.61,8.2-11.08,9.23-19.35C391.57,252.06,388.05,253.6,388.31,253.48z"
      />
    </svg>
  )
}
